<template>
  <nav id="header" class="fixed w-full z-30 top-0 "
       :class="scrollPosition > 10 ? 'bg-white text-gray-800 shadow' : 'text-white'">
    <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
      <div class="pl-4 flex items-center">
        <a class="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl" href="#">
          <img class="logo" id="logo" style="max-width: 8vw;" src="/img/kidsday_logo.png" alt="logo"/>
        </a>
      </div>
      <div class="block lg:hidden pr-4">
        <button id="nav-toggle" @click="hideMenu = !hideMenu"
                class="flex items-center p-1 text-pink-800 hover:text-gray-900 focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
          <svg class="fill-current h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
          </svg>
        </button>
      </div>
      <div ref="nav-content" class="w-full bg-white flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 lg:bg-transparent text-black p-4 lg:p-0 z-20"
          :class="{'bg-white': scrollPosition > 10, 'bg-gray-100': !scrollPosition > 10, 'hidden': hideMenu}"
          id="nav-content">
        <ul class="list-reset lg:flex justify-end flex-1 items-center">
          <li class="mr-3">
            <router-link to="/" id="home" class="menu menu-button">Home</router-link>
          </li>
          <div class="sub-menu">
            <router-link to="/informatie" id="informatie" class="menu menu-button">Informatie</router-link>
            <ul class="sub-menu-content">
              <router-link to="/bezoekers" id="bezoekers" class="menu menu-button">Informatie voor bezoekers
              </router-link>
              <router-link to="/vrijwilligers" id="vrijwilligers" class="menu menu-button"> Vrijwilligers informatie
              </router-link>
            </ul>
          </div>
          <li class="mr-3">
            <router-link to="/sponsoren" id="sponsoren" class="menu menu-button">Sponsoren</router-link>
          </li>
          <li class="mr-3">
            <router-link to="/verlanglijstje" id="verlanglijstje" class="menu menu-button">Verlanglijstje</router-link>
          </li>
          <li class="mr-3">
            <router-link to="/disclaimer" id="disclaimer" class="menu menu-button">Disclaimer</router-link>
          </li>
        </ul>
      </div>
    </div>
    <hr class="border-b border-gray-100 opacity-25 my-0 py-0"/>
  </nav>
</template>

<script>
export default {
  name: 'MenuComponent',
  props: {
    msg: String
  },
  data() {
    return {
      scrollPosition: null,
      navMenuDiv: document.getElementById("nav-content"),
      navMenu: document.getElementById("nav-toggle"),
      hideMenu: true,
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-button:hover {
  color: darkblue;
}
</style>
