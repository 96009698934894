<template>
  <section class="bg-white border-b py-8">
    <div class="container mx-auto flex flex-wrap pt-4 pb-12">
      <h2 class="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">
        Contact
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
        <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
          <div class="w-full font-bold text-xl text-gray-800 px-6 mb-2">
            Adres
          </div>
          <p class="text-gray-800 text-base px-6 mb-5">
            Locatie Kidsday : Sint Agathaplein in Boekel <br/>
            Postadres: Helfrichstraat 64 5427 SG Boekel
          </p>
        </div>

        <div class="flex-none mt-auto bg-white rounded-lg rounded-t-none overflow-hidden shadow p-3">
          <div class="flex items-center justify-between">
            <a href="https://www.google.com/maps/place/Sint+Agathaplein,+5427+AB+Boekel/@51.6027596,5.6706471,17z/data=!3m1!4b1!4m6!3m5!1s0x47c71eccc59b61c1:0x83f03b3b3800c2bf!8m2!3d51.6027596!4d5.6728358!16s%2Fg%2F1tgj816c" class="button mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-lg my-6 py-3 px-3 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
              Adres Kidsday
            </a>
            <a href="https://www.google.com/maps/place/Helfrichstraat+64,+5427+SG+Boekel/@51.6008696,5.6651583,17z/data=!3m1!4b1!4m6!3m5!1s0x47c71ecda18f1a7f:0xcd4dd22977d06802!8m2!3d51.6008697!4d5.669643!16s%2Fg%2F11c2gtyqnd" class="button mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-lg my-6 py-3 px-3 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
              postadres
            </a>
          </div>
        </div>

      </div>
      <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
        <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
          <div class="w-full font-bold text-xl text-gray-800 px-6 mb-2">
            Telefoon
          </div>
          <p class="text-gray-800 text-base px-6 mb-5">
            Carlo van Hout: 0642501527
          </p>
        </div>
        <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
          <div class="flex items-center justify-center">
            <a href="tel:0642501527" class=" button mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-lg my-6 py-3 px-5 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
              Bellen
            </a>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/3 p-6 flex flex-col flex-grow flex-shrink">
        <div class="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow">
          <div class="w-full font-bold text-xl text-gray-800 px-6 mb-2">
            Email
          </div>
          <a href="mailto:contact@kidsday.nl" class="text-gray-800 text-base px-6 mb-5 mail">contact@kidsday.nl</a>
        </div>
        <div class="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow p-6">
          <div class="flex items-center justify-center">
            <a href="mailto:contact@kidsday.nl" class=" button mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-lg my-6 py-3 px-5 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
              Stuur een email
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactComponent',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #FFFFFF;
}
.mail{
  color: black;
}
</style>
