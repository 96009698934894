<template>
  <!--Hero-->
  <div class="pt-10">
    <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
      <!--Left Col-->
      <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
<!--        <img class="w-full md:w-4/5 z-50" src="/img/kidsday_logo.png"  alt="logo"/>-->
        <img alt="backgroundimage" class="w-full md:w-9/10 z-50 pt-10 shadow-2xl border-black" src="/img/background.jpg"/>
      </div>
      <!--Right Col-->
      <div class="w-full md:w-3/5 py-6 text-center">
        <h1 class="my-4 text-3xl font-bold leading-tight pb-4">
          Kidsday: &eacute;cht even MET elkaar
        </h1>
        <router-link to="verlanglijstje" class="button mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
          Verlanglijstje
        </router-link>
      </div>
    </div>
  </div>

<HeaderComponent/>

  <section class="bg-white border-b py-8">
    <div class="container max-w-5xl mx-auto m-8">

      <div class="timer text-black" id="countdown"></div>

      <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Home
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-5/6 sm:w-1/2 p-6">
          <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
            STICHTING KIDSDAY
          </h3>
          <p class="text-gray-600 mb-8">
            Onze belangrijkste doelstelling is het organiseren van een jaarlijks terugkerend evenement, genaamd KidsDay.
          </p>
        </div>
        <div class="w-full sm:w-1/2 p-6">
          <img class="w-full md:w-2/3 z-50" src="/img/2023-5.jpg" />
        </div>
      </div>


      <div class="flex flex-wrap flex-col-reverse sm:flex-row">
        <div class="w-full sm:w-1/2 p-6 mt-6">
          <img class="w-full md:w-4/5 z-50" src="/img/2023-2.jpg" />
        </div>
        <div class="w-full sm:w-1/2 p-6 mt-6">
          <div class="align-middle">
            <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
              Plezier voor iedereen
            </h3>
            <p class="text-gray-600 mb-8">
              KidsDay is een organisatie met als doelstelling kinderen met en zonder beperking (maar ook de ouders) met elkaar in contact te brengen,
              en daardoor kennis en begrip over te dragen. Plezier voor alle kinderen staat voorop.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Contact></Contact>
  <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact";
import HeaderComponent from "@/components/Header";

export default {
  name: 'App',
  components: {
    HeaderComponent,
    Footer,
    Contact
  }
}
</script>

<style>
.menu-button:hover{
  color:darkblue;
}
.timer {
  font-size: 2rem;
  text-align: center;
  margin: 2rem;
}
</style>
