<template>
  <Header></Header>
  <section class="bg-white border-b py-8">
    <div class="container max-w-5xl mx-auto m-8">
      <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Informatie
      </h2>

      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="bezoekersinfo">
        <router-link to="bezoekers" class="menu menu-button submenu-item button mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-3 px-6 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
          Informatie bezoekers
        </router-link>
      </div>
      <div class="vrijwilligersinfo">
        <router-link to="vrijwilligers" class="menu menu-button submenu-item button mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-3 px-6 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
          Informatie vrijwilligers
        </router-link>
      </div>
      <br/>
      <p class="text-gray-600 mb-8">Op KidsDay zijn onder andere de volgende activiteiten:</p>
      <div class="flex flex-wrap">
        <div class="w-5/6 sm:w-1/2 p-6">
          <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
            Hippe Gasten
          </h3>
        </div>
        <div class="w-full sm:w-1/2 p-6">
          <img class="z-50 w-2/2" src="img/2023-7.jpg" />
        </div>
      </div>


      <div class="flex flex-wrap flex-col-reverse sm:flex-row">
        <div class="w-full sm:w-1/2 p-6 mt-6">
          <img class="z-50 w-2/2" src="img/2023-4.jpg" />
        </div>
        <div class="w-full sm:w-1/2 p-6 mt-6">
          <div class="align-middle">
            <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
              Springkussens
            </h3>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap">
        <div class="w-4/5 sm:w-1/2 p-6">
          <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
            Loterij en DJ
          </h3>
        </div>
        <div class="w-full sm:w-1/2 p-6">
          <img class="w-4/5 z-50" src="img/2023-1.jpg" />
        </div>
      </div>
      <p>Ieder jaar komen er weer nieuwe ideeën en dus nieuwe activiteiten bij!</p>
    </div>
  </section>
  <Contact></Contact>
  <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact.vue";
import Header from "@/components/Header.vue";

export default {
  name: 'App',
  components: {
    Footer,
    Contact,
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
