<template>
  <div id="container">
<MenuComponent></MenuComponent>
    <router-view></router-view>
  </div>
</template>

<script>

import MenuComponent from "@/components/Menu";

export default {
  name: 'App',
  components: {
    MenuComponent
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}
</style>
