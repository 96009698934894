<template>
  <Header></Header>
  <section class="bg-white border-b py-8">
    <div class="container max-w-5xl mx-auto m-8">
      <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Sponsoren
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
    </div>
    <Grid></Grid>
  </section>
  <Contact></Contact>
  <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact";
import Header from "@/components/Header";
import Grid from "@/components/Grid";

export default {
  name: 'App',
  components: {
    Grid,
    Footer,
    Contact,
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
