<template>
  <div>
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
        <path id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"/>
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"/>
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"/>
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"/>
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'HeaderComponent',
  components: {},
  props: {
    msg: String
  },
  data() {
    return {
      scrollpos : window.scrollY,
      header : document.getElementById("header"),
      navcontent : document.getElementById("nav-content"),
      toToggle : document.querySelectorAll(".toggleColour"),
      home : document.getElementById('home'),
      informatie : document.getElementById('informatie'),
      sponsoren : document.getElementById('sponsoren'),
      verlanglijstje : document.getElementById('verlanglijstje'),
      disclaimer : document.getElementById('disclaimer'),
      contact : document.getElementById('contact'),
      bezoekers : document.getElementById('bezoekers'),
      vrijwilligers : document.getElementById('vrijwilligers'),
      navMenuDiv : document.getElementById("nav-content"),
      navMenu : document.getElementById("nav-toggle"),
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    handleScroll () {
      /*Apply classes for slide in bar*/
      this.scrollpos = window.scrollY;

      // if (this.scrollpos > 10) {
      //   this.header.classList.add("bg-white");
      //   //Use to switch toggleColour colours
      //   for ( i = 0; i < this.toToggle.length; i++) {
      //     this.toToggle[i].classList.add("text-gray-800");
      //     this.toToggle[i].classList.remove("text-white");
      //   }
      //   this.header.classList.add("shadow");
      //   this.navcontent.classList.remove("bg-gray-100");
      //   this.navcontent.classList.add("bg-white");
      // } else {
      //   this.header.classList.remove("bg-white");
      //   //Use to switch toggleColour colours
      //   for (var i = 0; i < this.toToggle.length; i++) {
      //     this.toToggle[i].classList.add("text-white");
      //     this.toToggle[i].classList.remove("text-gray-800");
      //   }
      //
      //   this.header.classList.remove("shadow");
      //   this.navcontent.classList.remove("bg-white");
      //   this.navcontent.classList.add("bg-gray-100");
      // }
    },

//     check(e) {
//   var target = (e && e.target) || (event && event.srcElement);
//
//   //Nav Menu
//   if (!this.checkParent(target, this.navMenuDiv)) {
//     // click NOT on the menu
//     if (this.checkParent(target, this.navMenu)) {
//       // click on the link
//       if (this.navMenuDiv.classList.contains("hidden")) {
//         this.navMenuDiv.classList.remove("hidden");
//       } else {
//         this.navMenuDiv.classList.add("hidden");
//       }
//     } else {
//       // click both outside link and outside menu, hide menu
//       this.navMenuDiv.classList.add("hidden");
//     }
//   }
// },

   checkParent(t, elm) {
      while (t.parentNode) {
        if (t === elm) {
          return true;
        }
        t = t.parentNode;
      }
    return false;
}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-button:hover{
  color:darkblue;
}
</style>
