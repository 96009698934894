<template>
  <Header></Header>
  <section class="bg-white border-b py-8">
    <div class="container max-w-5xl mx-auto m-8">
      <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Disclaimer
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="w-full sm:w-1/2 m-auto">
        <p><strong>Privacy</strong></p>
        <p>* Bij publicatie van informatie zal (strikt) persoonlijke informatie worden weggelaten<br>
          op de website.</p>
        <p>* Bij vermelding op de website van persoonlijke gegevens van hen die bij Stichting<br>
          KidsDay zijn betrokken, zal nooit meer worden gepubliceerd dan vrij<br>
          verkrijgbare informatie.</p>
        <p>* Bij publicatie van informatie op de website, waar een betrokkene bezwaar tegen maakt,<br>
          zal degene die de site onderhoudt deze informatie op verzoek verwijderen.</p>
        <br/>
        <p><strong>Disclaimer</strong><br>
          * Aan de teksten op deze website kunnen geen rechten worden ontleend.</p>
        <p>* De redactie van deze website draagt geen enkele verantwoordelijkheid voor informatie op enige website of webpagina waarnaar door en onder deze website wordt verwezen.</p>
        <p>* Hoewel bij het samenstellen van deze website de uiterste zorg is nagestreefd, kan voor de aanwezigheid van eventuele (type)fouten en onvolledigheden niet worden ingestaan en aanvaardt de redactie van deze website deswege geen aansprakelijkheid.</p>
        <p>* De redacteur kan volledigheid en de juistheid van de geboden informatie niet garanderen.<br>
          U wordt derhalve aangeraden, voordat u handelt op basis van de geboden informatie, deze eerst te verifiëren bij de aanbieder en naar de van toepassing zijnde voorwaarden te vragen.</p>
        <p>*Via deze website wordt middels hyperlinks toegang geboden tot websites en/of informatiebronnen die door derden worden onderhouden.<br>
          De redacteur van deze website kan niet aansprakelijk worden gehouden voor de gevolgen van het gebruik van deze websites en/of informatiebronnen.</p>
        <p>* Hoewel de redactie van deze website ernaar streeft om op deze website betrouwbare en actuele informatie te verschaffen, kan de redacteur niet garanderen dat de informatie juist is in de context waarin deze wordt gebruikt.</p>
        <p>* De informatie wordt gegeven zoals ze is. In geen geval zal de redactie van deze website aansprakelijk gesteld kunnen worden voor enige schade die het resultaat is of zou zijn van levering, werking of gebruik van de informatie die deze website levert.</p>
        <p>* Het gebruik van de informatie uit deze website en de daaraan gekoppelde pagina’s c.q. websites is voor eigen risico van de gebruiker.</p>
      </div>
    </div>
  </section>
  <Contact></Contact>
  <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact";
import Header from "@/components/Header";

export default {
  name: 'App',
  components: {
    Footer,
    Contact,
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
