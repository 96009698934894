<template>
  <Header></Header>
  <section class="bg-white border-b py-8">
    <div class="container max-w-5xl mx-auto m-8">
      <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Informatie voor vrijwilligers
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-5/6 sm:w-1/2 p-6">
          <div class="entry-content">
            <p><strong>Vrijwilligers informatie:</strong></p><br>
            <p>Beste (aankomende) vrijwilligers,</p>
            <p>Voor de editie van KidsDay 2024 zijn we inmiddels al op zoek naar de broodnodige vrijwilligers. De website/Facebook zal regelmatig worden bijgehouden zodat het overzicht actueel blijft.</p>
            <p>Voor meer informatie of aanmelden als vrijwilliger kun je contact opnemen met Carlo van Hout via <a href="mailto:Penningmeester@kidsday.nl">contact@kidsday.nl</a> of&nbsp; 06-42501527</p>
            <p>Momenteel zoeken we nog&nbsp;vrijwilligers voor:</p><br>
            <p><strong><em>Zaterdag 22 juni 2024:</em></strong></p>
            <p>Een aantal vrijwilligers voor alle voorkomende werkzaamheden aangaande het opbouwen van KidsDay. Zowel lichtere als zwaardere taken.</p><br>
            <p><strong><em>Zondag 23 juni 2024:</em></strong></p>
            <p>Vrijwilligers voor KidsDay:<br>
              <span style="text-decoration: underline;"> Van 11.00 tot 21.00 uur</span></p>
          </div>
        </div>
        <div class="w-full sm:w-1/2 p-6">
          <img class="w-full md:w-4/5 z-50" src="img/vrijwilligers.jpg"  alt="vrijwilligers"/>
        </div>
      </div>
    </div>
  </section>
  <Contact></Contact>
  <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact";
import Header from "@/components/Header";

export default {
  name: 'App',
  components: {
    Footer,
    Contact,
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
