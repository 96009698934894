<template>
  <Header></Header>
  <section class="bg-white border-b py-8">
    <div class="container max-w-5xl mx-auto m-8">
      <h2 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
        Verlanglijstje
      </h2>
      <div class="w-full mb-4">
        <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-5/6 sm:w-1/2 p-6">
          <p>Zoals je wel kan begrijpen kunnen wij dit evenement niet organiseren zonder de<br>
            hulp van vele bedrijven, instanties en personen die materialen, geld en diensten<br>
            beschikbaar stellen.
            Zonder hen heeft KidsDay geen bestaan en daarvoor zijn wij hen dan ook heel erg<br>
            dankbaar.</p><br>
          <p>Ieder jaar opnieuw hebben we al deze diensten weer nodig en schrijven dan ook<br>
            bekende en nieuwe bedrijven aan om alles weer rond te krijgen.</p><br>
          <p>Om het gehele proces makkelijk te maken publiceren we ieder jaar deze verlang-<br>
            lijst, en roepen we dan ook iedereen op om te kijken wat diegene eventueel<br>
            beschikbaar kan/wil stellen.<br>
            Deze lijst zal dan ook zo vaak mogelijk aangepast worden.<br>
            Nieuwe dingen komen erbij en uiteraard gaan er ook steeds meer dingen af.</p><br>
          <p>Blijf deze lijst dus in de gaten houden, indien je interesse hebt kun je altijd contact&nbsp;opnemen via <a href="mailto:info@kidsday.nl">contact@kidsday.nl</a>&nbsp; of bel 06-42501527 (Carlo van Hout)</p>
          <ul>
            <li>5 meter kabelbrug</li>
            <li>Elektrische ballonpomp</li>
            <li>Vlaggenlijnen</li>
            <li>Aankleedkussens</li>
            <li>Prijzen voor de loterij</li>
          </ul>
        </div>
        <div class="w-full sm:w-1/2 p-6">
          <img class="w-full md:w-4/5 z-50" src="img/2023-4.jpg"  alt="springkussen"/>
        </div>
      </div>
    </div>
  </section>
  <Contact></Contact>
  <Footer></Footer>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Contact from "@/components/Contact";
import Header from "@/components/Header";

export default {
  name: 'App',
  components: {
    Footer,
    Contact,
    Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
